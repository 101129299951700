
import { defineComponent } from "vue";
import SinglePageLayout from "@/views/frontend/components/SinglePageLayout.vue";

export default defineComponent({
  name: "frequently-asked-questions",
  components: { SinglePageLayout },
  created() {
    //Set page title
    document.title =
      "Frequently Asked Questions | " + process.env.VUE_APP_TITLE;
  }
});
