
import { defineComponent } from "vue";

export default defineComponent({
  name: "single-page-layout-component",
  components: {},
  props: {
    title: String,
    subTitle: String,
  },
});
